import { LinkGetProps } from '@reach/router'

// getProps doesn't pass the prop onto react, it applies it as an attribute to the final element.
export const getProps = ({ location, href }: LinkGetProps) => ({
  'aria-current': href.split('?')[0] === location.pathname ? 'page' : null,
})

export const getBasePath = () => {
  return process.env.REACT_APP_BASE_PATH || '/'
}
