import { isSSR } from '../utils/ssr'
import { PUBLIC_ENV_SCRIPT_ID } from './scriptIds'

/*
 * List of environment variables to be made available on the public
 * site in production. Do not include sensitive api keys here.
 * Uses process.env as a fallback for local development.
 */
export const PUBLIC_ENV_VARIABLE_KEYS = [
  'REACT_APP_GRAPH_ENDPOINT',
  'REACT_APP_ENVIRONMENT',
  'REACT_APP_SEARCH_ENDPOINT',
  'REACT_APP_SPECTATOR_ENDPOINT',
  'REACT_APP_SPECTATOR_WS_ENDPOINT',
  'REACT_APP_BENCH_URL',
  'REACT_APP_AMPLIFY_USER_POOL_WEB_CLIENT_ID',
  'REACT_APP_AMPLIFY_USER_POOL_ID',
  'REACT_APP_CA_ACCOUNT_LINKING_AUTH_URL',
  'REACT_APP_CA_ACCOUNT_LINKING_CLIENT_ID',
  'REACT_APP_CA_ACCOUNT_LINKING_REDIRECT_URI',
  'REACT_APP_SENTRY_DSN',
  'REACT_APP_DATADOG_APPLICATION_ID',
  'REACT_APP_DATADOG_CLIENT_TOKEN',
  'REACT_APP_OAUTH_DOMAIN',
  'REACT_APP_OAUTH_REDIRECT_SIGN_IN',
  'REACT_APP_OAUTH_REDIRECT_SIGN_OUT',
  'REACT_APP_STRIPE_KEY',
  'REACT_APP_SPLITIO_API_KEY',
  'REACT_APP_GOOGLE_RECAPTCHA_KEY',
  'REACT_APP_GRAFANA_FARO_URL',
  'REACT_APP_GRAFANA_FARO_TRACING_ENABLED',
  'REACT_APP_POSTHOG_API_HOST',
  'REACT_APP_POSTHOG_API_KEY',
] as const

const publicEnvData = isSSR
  ? null
  : document.getElementById(PUBLIC_ENV_SCRIPT_ID)?.innerHTML

export const publicEnv: Record<
  typeof PUBLIC_ENV_VARIABLE_KEYS[number],
  string | undefined
> = (publicEnvData && JSON.parse(publicEnvData)) || process.env
