import theme from '@gameonsports/components/cjs/theme'
import React from 'react'

export interface ThemeContextValues {
  background: keyof typeof theme | null
  setBackground: React.Dispatch<React.SetStateAction<keyof typeof theme | null>>
  hideSwishBackground: boolean | null
  setHideSwishBackground: React.Dispatch<React.SetStateAction<boolean | null>>
  isWebview: boolean

  hideHeader: boolean
  setHideHeader: React.Dispatch<React.SetStateAction<boolean>>

  hideFooter: boolean
  setHideFooter: React.Dispatch<React.SetStateAction<boolean>>

  hideFooterBanner: boolean
  setHideFooterBanner: React.Dispatch<React.SetStateAction<boolean>>
}

const ThemeContext = React.createContext<ThemeContextValues>({
  background: null,
  setBackground: () => null,
  hideSwishBackground: null,
  setHideSwishBackground: () => null,
  isWebview: false,

  hideHeader: false,
  setHideHeader: () => null,

  hideFooter: false,
  setHideFooter: () => null,

  hideFooterBanner: false,
  setHideFooterBanner: () => null,
})

export default ThemeContext
