import { useLocation } from '@reach/router'
import { parse } from 'query-string'
import { useCallback } from 'react'
import { navigate } from '../components/Router'

export const useQueryString = () => {
  const location = useLocation()
  const queryParams = parse(
    /**
     * Using `window` over reach router is required for unit tests since the
     * navigate method does not update the history object without workarounds
     * https://github.com/reach/router/issues/225
     **/
    typeof window !== 'undefined' ? window.location.search : location.search,
  )

  const buildQueryString = useCallback((params: Record<string, any>) => {
    return `?${Object.keys(params)
      .filter(key => params[key])
      .map(key => `${key}=${params[key]}`)
      .join('&')}`
  }, [])

  const setQueryString = useCallback(
    (params: Record<string, any>) => {
      navigate(buildQueryString(params))
    },
    [buildQueryString],
  )

  return { queryParams, setQueryString, buildQueryString }
}
