import Loader from '@gameonsports/components/cjs/Loader'
import loadable from '@loadable/component'
import { RouteComponentProps, useLocation, useNavigate } from '@reach/router'
import React, { useEffect } from 'react'
import PageLoader from '../../components/PageLoader'
import { useGameManageJwtGenerateMutation } from '../../generated/graphql'
import { getLocationParamsV2 } from '../../utils/location'

export const GAME_MANAGE_JWT_STORAGE_KEY = 'GAME_MANAGE_JWT'
export const GAME_MANAGE_TENANT_STORAGE_KEY = 'GAME_MANAGE_TENANT'

const NotFound = loadable(
  () => import('../404' /* webpackChunkName: "404" */),
  { fallback: <PageLoader /> },
)

const GameManageCallback = (_: RouteComponentProps) => {
  const [show404, set404] = React.useState(false)
  const location = useLocation()
  const navigate = useNavigate()
  const { tenant, token } =
    getLocationParamsV2<{ tenant: string; token: string }>(location)

  const [generateJWT] = useGameManageJwtGenerateMutation()

  useEffect(() => {
    // Handle location params missing.
    if (!token || !tenant) {
      set404(true)
      return
    }

    // Call the mutation on mount.
    generateJWT({
      variables: {
        token,
      },
    })
      .then(({ data }) => {
        const { jwt, gameID } = data?.gameManageJWTGenerate || {}

        // Handle 404 error for missing data.
        if (!jwt || !gameID) {
          set404(true)
          return
        }

        // Save auth token and tenant in local storage, this will be loaded under
        // the `/game-manage/:gameID` route.
        sessionStorage.setItem(GAME_MANAGE_JWT_STORAGE_KEY, jwt)
        sessionStorage.setItem(GAME_MANAGE_TENANT_STORAGE_KEY, tenant)

        // Navigate to access the game.
        navigate(`/game-manage/${gameID}`, { replace: true })
      })
      .catch(() => {
        set404(true)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (show404) {
    return <NotFound />
  }

  return <Loader />
}

export default GameManageCallback
