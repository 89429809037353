import posthog from 'posthog-js'
import { PostHogProvider as Provider } from 'posthog-js/react'
import React, { useEffect } from 'react'
import { publicEnv } from '../../constants/publicEnv'
import useFeatureFlagOn from '../../hooks/useFeatureFlagOn'

export const AnalyticsProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const isPostHogOn = useFeatureFlagOn('posthog-eu-hosting')

  useEffect(() => {
    if (isPostHogOn && publicEnv.REACT_APP_POSTHOG_API_KEY) {
      posthog.init(publicEnv.REACT_APP_POSTHOG_API_KEY, {
        api_host: publicEnv.REACT_APP_POSTHOG_API_HOST,
        person_profiles: 'identified_only',
        autocapture: false,
        capture_pageview: false,
      })
    }
  }, [isPostHogOn])

  if (!isPostHogOn) {
    return <>{children}</>
  }

  return <Provider client={posthog}>{children}</Provider>
}
