import { WindowLocation } from '@reach/router'
import omitBy from 'lodash/fp/omitBy'

export interface LocationParams {
  [key: string]: string | undefined
}

export const getLocationParams: (
  location: Pick<WindowLocation | Location, 'search'>,
) => LocationParams = location => {
  const params = location.search.slice(1).split('&')

  return params.reduce((paramsObj: LocationParams, param: string) => {
    const separatorIndex = param.indexOf('=')
    const currentParam = {
      name: param.slice(0, separatorIndex),
      value: param.slice(separatorIndex + 1),
    }

    paramsObj[currentParam.name] = currentParam.value
    return paramsObj
  }, {} as LocationParams)
}

/**
 * Same functionality as getLocationParams, but:
 * 1. Uses URLSearchParams instead of manually parsing the string.
 * 2. Has a generic type that provides the ability to avoid typecasting when using this function.
 */
export function getLocationParamsV2<T extends Partial<LocationParams>>(
  location?: WindowLocation,
): Partial<T> {
  if (!location) return {} as T

  const searchParams = new URLSearchParams(location.search)
  const result: T = {} as T

  for (const [key, value] of searchParams) {
    result[key as keyof T] = decodeURIComponent(value) as T[keyof T]
  }

  return result
}

/**
 * joins URL paths into a single path, ensuring proper formatting
 */
export function joinUrlPaths(...paths: string[]) {
  return paths.reduce((acc, item) => {
    if (acc.endsWith('/') && item.startsWith('/')) {
      return `${acc}${item.slice(1)}`
    }

    if (acc.endsWith('/') || item.startsWith('/') || acc === '') {
      return `${acc}${item}`
    }

    return `${acc}/${item}`
  }, '')
}

type NonNullableProperties<T> = {
  [K in keyof T]: Exclude<T[K], null | undefined>
}

export function withoutEmpty<T extends Record<string, unknown>>(val: T) {
  return omitBy(x => !x)(val) as NonNullableProperties<T>
}
