import {
  Link as BaseLink,
  LinkProps,
  NavigateOptions,
  navigate as navigateBase,
  useNavigate as useNavigateBase,
} from '@reach/router'
import React from 'react'
import { joinUrlPaths } from '../../utils/location'
import { getBasePath } from '../../utils/route'

/**
 * Set of wrappers around reach-router components
 * to ensure basepath is applied for links.
 * Can be removed after migrating to react-router v6+
 */
export const Link = (
  props: LinkProps<{}> & React.RefAttributes<HTMLAnchorElement>,
) => {
  const shouldPrependBasePath = !props.to.startsWith(getBasePath())

  if (props.to.startsWith('/') && shouldPrependBasePath) {
    return (
      <BaseLink {...props} to={joinUrlPaths(getBasePath(), props.to)}>
        {props.children}
      </BaseLink>
    )
  }

  return <BaseLink {...props}>{props.children}</BaseLink>
}

export const prependBasePathToUrl = (url: string) => {
  const shouldPrependBasePath = !url.startsWith(getBasePath())

  if (url.startsWith('/') && shouldPrependBasePath) {
    return joinUrlPaths(getBasePath(), url)
  }

  return url
}

export function useNavigate(): (
  to: string,
  options?: NavigateOptions<{}>,
) => Promise<void> {
  const navigate = useNavigateBase()

  return (to, options) => {
    const shouldPrependBasePath = !to.startsWith(getBasePath())

    return navigate(
      to.startsWith('/') && shouldPrependBasePath
        ? joinUrlPaths(getBasePath(), to)
        : to,
      options,
    )
  }
}

export function navigate(to: string, options?: NavigateOptions<{}>) {
  const shouldPrependBasePath = !to.startsWith(getBasePath())

  return navigateBase(
    to.startsWith('/') && shouldPrependBasePath
      ? joinUrlPaths(getBasePath(), to)
      : to,
    options,
  )
}
